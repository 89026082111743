Default styles for larger screens * {
  margin: 0;
  padding: 0;
}

.bd {
  font-size: 50px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-weight: thin;
  text-align: center;
  color: #000000;
  position: absolute;
  background-image: url(../images/pic.png);
  background-size: cover;
  overflow-x: hidden;
  overflow-y: auto;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  padding-top: 20px;
}

.Heading {
  margin-top: 130px;
}

/* CSS */
.button-89 {
  --b: 3px; /* border thickness */
  --s: 0.75em; /* size of the corner */
  --color: #373b44;

  padding: calc(0.2em + var(--s)) calc(0.9em + var(--s));
  color: var(--color);
  --_p: var(--s);
  background: conic-gradient(
      from 90deg at var(--b) var(--b),
      #0000 90deg,
      var(--color) 0
    )
    var(--_p) var(--_p) / calc(100% - var(--b) - 2 * var(--_p))
    calc(100% - var(--b) - 2 * var(--_p));
  transition:
    0.3s linear,
    color 0s,
    background-color 0s;
  outline: var(--b) solid #0000;
  outline-offset: 0.6em;
  font-size: 2rem;
  border: 0;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin-top: 6rem;
}

.button-89:hover,
.button-89:focus-visible {
  --_p: 0px;
  outline-color: var(--color);
  outline-offset: 0.05em;
  cursor: pointer;
}

.button-89:active {
  background: var(--color);
  color: #fff;
}

@media (max-width: 768px) {
  .bd {
    font-size: 30px;
  }

  .btn {
    font-size: 1.4rem;
    margin-right: 20px;
  }
}

@media (max-width: 480px) {
  .bd {
    font-size: 20px;
  }

  .btn {
    font-size: 1rem;
    padding: 8px 16px;
    margin-right: 10px;
  }
}
